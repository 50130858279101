import React from 'react';
import { fragments } from './fragments';
import { yesNoOptions } from './utils';

export default {
  types: {
    job: {
      renderers: {
        inline: ({ node }) => <>{node.shortId} - {node.title}</>,
      },

      selections: {
        default: `
          ${fragments.job.basic}
          tasks { id task {
            ${fragments.task.basic}
          }}
          files(order_by: {file: {updatedAt: desc}}) { id file {
            ${fragments.file.basic}
          }}
          forms {id form{id title jobs {id job{id}}}}
          tags {id tag {id label type color icon}}
          firstWorkEntry: workEntries(limit: 1, order_by: [{start: asc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          lastWorkEntry: workEntries(limit: 1, order_by: [{start: desc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          workEntries_aggregate {
            aggregate {
              sum {
                duration
              }
            }
          }
          assignedUsers {id user {
            id displayName email
            avatar {id url mimeType name }
          }}
          notedWorkEntries: workEntries(where: {notes: { _neq: "" }}){
            id start end duration notes
            user {id displayName avatar{id url}}
          }
          expenses_aggregate{aggregate{count}}
          change_orders_aggregate: forms_aggregate(where: {form: {name:{_eq: "ChangeOrderForm"}}}){aggregate{count}}
          resources{id resource{id title description link createdAt updatedAt userId expires files}}
          resources_aggregate{aggregate{count}}
        `,
      },

      fields: {
        files: {
          type: 'file',
          hasMany: true,
        },

        shortId: {
          type: 'String',
          label: 'Code',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        options: {
          type: 'Json',
        },

        specs: {
          type: 'Json',
        },

        customer: {
          type: 'contact',
          validation: {
            required: true,
          },
        },

        tasks: {
          type: 'task',
          hasMany: true,
          validation: {
            required: true,
          },
        },

        tags: {
          type: 'tag',
          hasMany: true,
          validation: { required: false },
        },

        forms: {
          type: 'form',
          hasMany: true,
          validation: { required: false },
        },

        assignedUsers: {
          type: 'user',
          hasMany: true,
          validation: { required: false },
        },

        resources: {
          type: 'resource',
          hasMany: true,
          validation: { required: false },
        },

        status: {
          type: 'String',
          options: [
            { value: 'active', primary: 'Active' },
            { value: 'complete', primary: 'Complete' },
            { value: 'archived', primary: 'Archived' },
          ],
          defaultValue: 'active',
          validation: {
            required: true,
          },
        },

        type: {
          type: 'String',
          validation: { required: true },
          options: [
            { value: 'concrete-other', primary: 'Concrete' },
            { value: 'yard-other', primary: 'Yard' },
            { value: 'repairs', primary: 'Repairs' },
            { value: 'pumping', primary: 'Pumping' },
            { value: 'other', primary: 'Other' },
            { value: 'concrete', primary: 'Concrete Load' },
            { value: 'yard-product', primary: 'Yard Load' },
          ],
        },

        deliverAt: {
          type: 'Date',
          validation: { required: true },
        },

        'specs.customerPo': { type: 'String', validation: { required: false } },

        'specs.billingInfoSameAsCustomer': { type: 'String', options: yesNoOptions, defaultValue: 'yes', validation: { required: true } },
        'specs.billingInfo': { type: 'String', validation: { requiredIf: { dep: 'specs.billingInfoSameAsCustomer', eq: 'no' } } },
        'specs.billStatus': { type: 'String', defaultValue: 'not-billed', validation: { required: true } },

        'specs.notes': { type: 'String', validation: { required: false } },
        'specs.bookkeeperNotes': { type: 'String', validation: { required: false } },
        'specs.officeManagerNotes': { type: 'String', validation: { required: false } },

        'specs.deliveryAddress': { type: 'String', validation: { required: false } },
        'specs.deliveryPhone': { type: 'String', validation: { required: true } },
        'specs.codNumber': { type: 'String', validation: { required: false } },
        'specs.accountNumber': { type: 'String', validation: { required: false } },
        'specs.payment': {
          type: 'String',
          optionsName: 'payment-by',
          validation: { required: true },
        },
        'specs.pump': { type: 'String', options: yesNoOptions, validation: { required: false } },
        'specs.weatherPending': { type: 'Boolean', validation: { required: false } },
        'specs.toBeConfirmed': { type: 'Boolean', validation: { required: false } },
        'specs.goOrder': { type: 'Boolean', validation: { required: false } },
        'specs.finisher': { type: 'String', validation: { required: false } },
        'specs.concreteMix': { type: 'String', optionsName: 'concrete-mix', validation: { requiredIf: { dep: 'type', eq: 'concrete' } } },
        'specs.concreteMixOther': { type: 'String', validation: { required: false } },
        'specs.yardMix': { type: 'String', optionsName: 'yard-product', validation: { requiredIf: { dep: 'specs.type', eq: 'yard-product' } } },
        'specs.yardMixOther': { type: 'String', validation: { required: false } },
        'specs.mpa': {
          type: 'String',
          optionsName: 'mpa',
          validation: { required: false },
        },
        'specs.aggregateSize': {
          type: 'String',
          optionsName: 'aggregate-size',
          validation: { required: false },
        },
        'specs.concreteType': {
          type: 'String',
          optionsName: 'concrete-type',
          validation: { required: false },
        },
        'specs.concreteTypeOther': { type: 'String', validation: { requiredIf: { dep: 'specs.concreteType', eq: 'other' } } },
        'specs.additives': { type: 'String', optionsName: 'concrete-additive', validation: { required: false } },
        'specs.amount': { type: 'String', validation: { required: true } },
        'specs.amountUnits': {
          type: 'String',
          options: [
            { value: 'm3', primary: 'm³' },
            { value: 'yrd3', primary: 'yrd³' },
            { value: 'tonne', primary: 'metric tons' },
          ],
          validation: { requiredIf: { dep: 'specs.amount', isTruthy: true } },
        },
      },
    },
  },
};
