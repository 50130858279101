import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { Grid } from 'src/kiska/components/Grid';
import { formatDate, replaceNewLinesWithBreaks } from 'src/kiska/utils';
import classNames from 'classnames';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { CheckBoxOutlineBlank as CheckboxEmptyIcon, CheckBox as CheckboxFilledIcon } from '@material-ui/icons';

// import adamsLogo from './images/adams-logo.png';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField/index.js';
import { FilePreviewsContainer } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreviewsContainer.js';
import adamsLogo from './images/adams-logo.js';
import pumpTruck from './images/pump.png';
import { jobTypeToIcon } from './utils.js';

const useStyles = makeStyles((theme) => {
  const styles = {
    root: {
      '& > div': {
        padding: theme.spacing(0.15),
      },
    },
    address: {
      lineHeight: 1.2,
      fontSize: '1em',
    },
    labelValueRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      marginRight: theme.spacing(0.5),
      fontSize: '1em',
    },
    value: {
      lineHeight: 1.2,
      backgroundColor: `hsla(0, 0%, 0%, 8%)`,
      borderRadius: 2,
      padding: theme.spacing(0.15, 0.5),
      margin: theme.spacing(0.25, 0),
      display: 'inline-block',
      flex: 1,
      fontSize: '1em',
      '&.strong': {
        fontWeight: 700,
        fontSize: '1.0em',
      },
    },
    checkboxLabel: {
      lineHeight: 1.2,
      backgroundColor: `hsla(0, 0%, 0%, 0%)`,
      borderRadius: 2,
      padding: theme.spacing(0.15, 0.5),
      margin: theme.spacing(0.25, 0),
      display: 'inline-block',
      flex: 1,
      fontSize: '1em',
      '&.strong': {
        fontWeight: 700,
        fontSize: '1.0em',
      },
    },
    title: {
      lineHeight: 1.1,
      fontSize: '1.2em',
    },
    notes: {
      fontStyle: 'italic',
      fontWeight: 300,
    },
  };

  if (theme.kiska.type === 'print') {
    styles.root = {
      ...styles.root,
      fontSize: 18,
      maxWidth: '4in',
      minWidth: '4in',
      width: '4in',
      flexBasis: '4in',
      // border: `dashed 2px #DDD`,
      padding: '0.25in',
      '& > div': {
        padding: 0,
      },
    };
    styles.value = {
      ...styles.value,
      backgroundColor: `hsla(0, 0%, 0%, 0%)`,
      borderBottom: 'solid 1px #DDD',
    };
    styles.checkboxLabel = {
      ...styles.checkboxLabel,
      borderBottom: 'none',
    };
  }

  return styles;
});

const LabelValue = (props) => {
  const classes = useStyles(props);
  const { label, value, strong, align, checkbox, trueColor } = props;

  if (checkbox) {
    return (
      <span className={classes.labelValueRoot}>
        {value ? <CheckboxFilledIcon style={{ color: trueColor }} /> : <CheckboxEmptyIcon />}
        {/* <Typography variant="body2" color="textSecondary" className={classes.label} component="span">{label}</Typography> */}
        <Typography
          variant="body2"
          color="textPrimary"
          align={align}
          className={classNames(classes.checkboxLabel, strong && 'strong')}
          component="span"
        >
          {label}
        </Typography>
      </span>
    );
  }

  return (
    <span className={classes.labelValueRoot}>
      {label && <Typography variant="body2" color="textSecondary" className={classes.label} component="span">{label}: </Typography>}
      <Typography
        variant="body2"
        color="textPrimary"
        align={align}
        className={classNames(classes.value, strong && 'strong')}
        component="span"
      >{value || <>&nbsp;</>}
      </Typography>
    </span>
  );
};

const JobBoardJobView = (props) => {
  const classes = useStyles(props);
  const { job } = props;
  const { getOption } = useSchema();
  const { printMode } = usePrint();
  const { getOption: getAppSettingsOption } = useAppSettings();
  const primary = (name) => getOption('job', name, job).primary;

  const icon = jobTypeToIcon(job.type);

  return (
    <>
      <Grid container spacing={printMode ? 2 : 0} className={classes.root}>
        {printMode && (
          <Grid item container xs={12} justify="space-between" style={{ marginBottom: -4 }}>
            <img src={adamsLogo} style={{ height: 40 }} />
            {job.specs.pump === 'yes' && <img src={pumpTruck} style={{ height: 40 }} />}
            {icon && <img src={icon} style={{ height: 40 }} />}
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" color="textPrimary" className={classes.title}>{job.customer.company || job.customer.name}</Typography>
        </Grid>

        <Grid item xs={true}>
          <Typography variant="body2" color="textSecondary" className={classes.address}>
            {replaceNewLinesWithBreaks(job.specs.deliveryAddress)}
            <Link href={`tel:${job.specs.deliveryPhone}`}>{job.specs.deliveryPhone || ''}</Link>
          </Typography>
        </Grid>
        <Grid item>
          <LabelValue align="left" value={getAppSettingsOption(job.specs.payment).primary} label="" />
        </Grid>
        <GridLineBreak />

        {job.type === 'concrete' && (
          <Grid item xs={12}>
            <LabelValue strong align="center" label="Mix" value={job.specs.concreteMix} />
            {/* <LabelValue strong align="center" label="Mix" value={getAppSettingsOption(job.specs.concreteMix).primary} /> */}
          </Grid>
        )}
        {job.type === 'concrete' && job.concreteMix === 'concrete-mix:other' && (
          <Grid item xs={6}>
            <LabelValue strong align="center" label="" value={getAppSettingsOption(job.specs.concreteMixOther).primary} />
          </Grid>
        )}
        {job.type === 'yard-product' && (
          <Grid item xs={12}>
            <LabelValue strong align="center" label="Product" value={job.specs.yardMix} />
            {/* <LabelValue strong align="center" label="Product" value={getAppSettingsOption(job.specs.yardMix).primary} /> */}
          </Grid>
        )}
        {job.type === 'yard-product' && job.yardMix === 'yard-product:other' && (
          <Grid item xs={6}>
            <LabelValue strong align="center" label="" value={getAppSettingsOption(job.specs.yardMixOther).primary} />
          </Grid>
        )}
        <GridLineBreak />

        {job.type === 'concrete' && (
          <>
            <Grid item xs={4}>
              <LabelValue align="left" label="MPA" value={getAppSettingsOption(job.specs.mpa).primary} />
            </Grid>
            <Grid item xs={8}>
              <LabelValue align="left" label="Aggs Size" value={getAppSettingsOption(job.specs.aggregateSize).primary} />
            </Grid>

            <Grid item xs={job.specs.concreteType === 'concrete-type:other' ? 4 : 12}>
              <LabelValue label="Type" value={getAppSettingsOption(job.specs.concreteType).primary} />
            </Grid>
            <Grid item xs={8}>
              {job.specs.concreteType === 'concrete-type:other' && <LabelValue label="" value={job.specs.concreteTypeOther} />}
            </Grid>

            <Grid item xs={12}>
              <LabelValue label="Adds" value={(job.specs.additives || []).map((v) => getAppSettingsOption(v).primary).join(', ')} />
            </Grid>
          </>
        )}

        <Grid item xs={4}>
          <LabelValue label="Go Order" value={job.specs.goOrder} checkbox trueColor="#0C0" />
        </Grid>
        <Grid item xs={6}>
          <LabelValue strong align="center" label="Amount" value={`${job.specs.amount}`} />
        </Grid>
        <Grid item xs={2}>
          <LabelValue strong align="center" label="" value={`${primary('specs.amountUnits')}`} />
        </Grid>

        <Grid item xs={undefined}>
          <LabelValue label="WP" value={job.specs.weatherPending} checkbox trueColor="#f7c400" />
        </Grid>
        <Grid item xs={undefined}>
          <LabelValue label="TBC" value={job.specs.toBeConfirmed} checkbox trueColor="#cd4838" />
        </Grid>
        {job.type === 'concrete' && (
          <Grid item xs={true}>
            <LabelValue label="Finisher" value={job.specs.finisher} />
          </Grid>
        )}
        <GridLineBreak />

        <Grid item xs={12}>
          {printMode
            ? <LabelValue label="Delivery Time" value={formatDate(job.deliverAt, `MMM d, h:mm:aaaaa'm`)} />
            : <LabelValue label="Delivery Time" value={formatDate(job.deliverAt, `h:mm:aaaaa'm`)} />}
        </Grid>

        {job.specs.notes && (
          <Grid item xs={12}>
            <LabelValue value={replaceNewLinesWithBreaks(job.specs.notes)} classes={{ value: classes.notes }} />
          </Grid>
        )}

        {!!job.files.length && (
          <Grid item xs={12}>
            <FilePreviewsContainer files={job.files.map((file) => file.file)} enableView />
          </Grid>
        )}
      </Grid>
    </>
  );
};

JobBoardJobView.propTypes = {
  job: PropTypes.object.isRequired,
};
JobBoardJobView.defaultProps = {
};

export { JobBoardJobView };
