import pickupIcon from './images/pickup.png';
import concreteIcon from './images/cement.png';
import gravelIcon from './images/gravel.png';

// fake data generator
const getItems = (count, offset = 0) => Array.from({ length: count }, (v, k) => k).map((k) => ({
  id: `item-${k + offset}-${new Date().getTime()}`,
  content: `item ${k + offset}`,
}));

const reorder = (list, startIndex, endIndexex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndexex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const jobTypeToIcon = (type) => {
  switch (type) {
    case 'concrete': return concreteIcon;
    case 'yard-product': return gravelIcon;
    default: return null;
  }
};

export { getItems, reorder, move, jobTypeToIcon };
