import React from 'react';
import { MobileDateTimePicker as MuiDateTimePicker, StaticDateTimePicker } from '@material-ui/pickers';
import DateAndOrTimePicker from './DateAndOrTimePicker';

const DateTimePicker = (props) => {
  if (props.static) {
    return <DateAndOrTimePicker component={StaticDateTimePicker} format="EEE MMM d, yyyy h:mmaaaaa'm" {...props} />;
  }
  return <DateAndOrTimePicker component={MuiDateTimePicker} format="EEE MMM d, yyyy h:mmaaaaa'm" {...props} />;
};

export default DateTimePicker;
