import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormSection } from 'src/components/forms';
import { Grid } from 'src/kiska/components/Grid';
import TagIcon from 'mdi-material-ui/Tag';
import OptionIcon from 'mdi-material-ui/FormDropdown';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { SimpleTable } from './SimpleTable';
import { TagUpdateForm } from './TagUpdateForm';
import { OptionUpdateForm } from './OptionUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
}));

const DataTab = (props) => {
  const classes = useStyles(props);
  const { getOption } = useSchema();

  return (
    <div className={classes.root}>
      <FormSection title="Options" icon={OptionIcon}>
        <Grid item xs={12}>
          <SimpleTable
            searchPlaceholder="Search"
            searchPaths="primary name"
            type="option"
            initial={{
              limit: 200,
              orderBy: [
                { name: 'asc' },
                { weight: 'asc' },
              ],
            }}
            columns={[
              {
                fieldName: 'name',
                label: 'Data Field',
                Renderer: ({ value }) => <Chip label={getOption('option', 'name', value).primary} />,
              },
              'primary',
              {
                fieldName: 'secondary',
                Renderer: ({ value }) => <Typography variant="body2" color="textSecondary">{value}</Typography>,
              },
              'weight',
            ]}
            UpdateForm={OptionUpdateForm}
            updateFormProps={{}}
            actionsOnBottom={false}
            actionsOnTop={false}
            enableAdd={true}
            enableEdit
            showSearchBox
            rowClickAction="edit"
          />
        </Grid>
      </FormSection>
      <FormSection title="Tags" icon={TagIcon}>
        <Grid item xs={12}>
          <SimpleTable
            searchPlaceholder="Search by label"
            searchPaths="label"
            type="tag"
            initial={{ limit: 200 }}
            columns={['label']}
            UpdateForm={TagUpdateForm}
            updateFormProps={{}}
            actionsOnBottom={false}
            actionsOnTop={false}
            enableAdd={false}
            enableEdit
            showSearchBox
            rowClickAction="edit"
          />
        </Grid>
      </FormSection>
    </div>
  );
};

DataTab.propTypes = {
};
DataTab.defaultProps = {
};

export { DataTab };
