import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNode, useNodes } from 'src/kiska/hooks/useNode';
import produce from 'immer';
import deepFreeze from 'deep-freeze';
import { parse } from 'date-fns';
import _ from 'lodash';
import { buildPayPeriodConfig } from '../NodeList/pay-periods';

const AppSettingsContext = createContext({});
const useAppSettings = () => useContext(AppSettingsContext);

const normalizeAppSettings = (appSettings) => {
  const newSettings = produce(appSettings, (draft) => {
    try {
      draft.data.payPeriodConfig = buildPayPeriodConfig(draft.data.payPeriods);
    } catch (error) {
      console.error(`Error normalizing App Settings: `, error);
      draft.data.payPeriodConfig = buildPayPeriodConfig({
        type: 'day-count',
        count: 14,
        start: parse('2020-04-12 00:00:00.0000', 'yyyy-MM-dd HH:mm:ss.SSSS', new Date()),
      });
    }

    draft.data.calendar = _.merge(draft.data.calendar, {
      colors: {
        users: {},
      },
    });
  });

  return newSettings;
};

const AppSettingsProvider = (props) => {
  const { result, node } = useNode({ type: 'app_setting', id: 'app-settings' });
  const { result: optionsResult, nodes: options } = useNodes({ type: 'option', limit: 1000, orderBy: [{ weight: 'asc' }] });
  const { children } = props;
  const loading = result.loading || !node || optionsResult.loading;

  if (loading) return null;

  const optionsMap = options.reduce((acc, option) => {
    if (!acc[option.name]) acc[option.name] = [];
    acc[option.name].push(option);
    return acc;
  }, {});

  const getOption = (value) => {
    const option = options.find((o) => o.value === value);
    const defaultSafeOption = {
      value,
      primary: '',
      secondary: '',
    };

    if (value && !option) {
      console.error(`Could not find app settings option "${value}".`);
      return defaultSafeOption;
    }
    return {
      ...defaultSafeOption,
      ...option,
    };
  };

  const value = {
    getOption,
    options: optionsMap,
    appSettings: normalizeAppSettings(node),
  };

  deepFreeze(value);

  return (
    <AppSettingsContext.Provider value={value}>
      {children}
    </AppSettingsContext.Provider>
  );
};

const withAppSettings = (WrappedComponent) => (props) => (
  <AppSettingsContext.Consumer>
    {(value) => <WrappedComponent appSettings={value.appSettings} appSettingsOptions={value.options} {...props} />}
  </AppSettingsContext.Consumer>
);

export { withAppSettings, AppSettingsProvider, AppSettingsContext, useAppSettings };
