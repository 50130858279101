import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import EditIcon from 'mdi-material-ui/Pencil';
import { Print as PrintIcon } from '@material-ui/icons';
import { GeneratePdfButton } from 'src/kiska/components/PdfGenerator';
import { buildPaperStyles } from './styles';
import { JobBoardJobView } from './JobBoardJobView';
import { jobTypeToIcon } from './utils';

import pumpTruck from './images/pump.png';

const useStyles = makeStyles((theme) => {
  const paperStyles = buildPaperStyles(theme);

  return {
    root: {
      padding: theme.spacing(1.5),
      margin: theme.spacing(0),
      position: 'relative',
      transform: `rotate(0)`,
      width: '100%',
      maxWidth: 500,
      '&:focus': {
        outlineColor: `hsla(60, 100%, 50%, 100%)`,
        outlineStyle: 'dashed',
        outlineWeight: `1px`,
      },
      '&.complete:not(:hover)': {
        opacity: 0.5,
      },
      // perspective: 500,
    },
    inner: {
      cursor: 'move',
      backgroundColor: 'hsla(60, 100%, 94%)',
      position: 'relative',
      padding: theme.spacing(0, 0),
      transform: `translateZ(0px)`,
      transformStyle: 'preserve-3d',
      transition: `transform 300ms ease-in-out`,
      ...paperStyles.box,
      '& .before, & .after': paperStyles.beforeAndAfter,
      '& .after': paperStyles.after,
      '& .before': paperStyles.before,
      '& .topRightFold': paperStyles.topRightFold,
      '&.dragging': {
        // transform: `rotate(0deg) scale(1) rotateX(10deg)`,
      },
    },
    content: {
      padding: theme.spacing(1, 1),
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(-1),
    },
    actionButton: {
      opacity: 0.4,
      '&:hover': {
        opacity: 1,
      },
    },
    icon: {
      height: 40,
    },
  };
});

const DraggableJob = (props) => {
  const { index, item, job, onUpdate, isDraggable, className } = props;
  const classes = useStyles(props);
  const printRef = useRef();

  const icon = jobTypeToIcon(job.type);

  const renderFunc = (provided, snapshot) => (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={classNames(snapshot.isDragging && 'dragging', classes.root, className, job.status === 'complete' && 'complete')}
      style={{ ...(provided.draggableProps ? provided.draggableProps.style : {}) }}
    >
      <div className={classNames(classes.inner, snapshot.isDragging && 'dragging')}>

        <div className="before" />
        <div className="after" />
        <div className="topRightFold" />

        <div className={classes.content} ref={printRef}>
          <JobBoardJobView job={job} />
        </div>

        <div className={classes.actions}>
          <GeneratePdfButton
            mode="print"
            component={IconButton}
            targetRef={printRef}
            name={job.title}
            className={classes.actionButton}
            format="A6"
            margin={{ top: '0', right: '0', bottom: '.75in', left: '0' }}
          >
            <PrintIcon />
          </GeneratePdfButton>

          {job.specs.pump === 'yes' && <img src={pumpTruck} className={classes.icon} />}
          {icon && <img src={icon} className={classes.icon} />}

          <IconButton className={classes.actionButton} onClick={onUpdate}><EditIcon /></IconButton>
        </div>

      </div>
    </div>
  );

  if (isDraggable) {
    return (
      <Draggable draggableId={job.id} index={index} isDragDisabled={!isDraggable}>
        {renderFunc}
      </Draggable>
    );
  }

  return renderFunc({}, {});
};

DraggableJob.propTypes = {
  job: PropTypes.object,
  item: PropTypes.object,
  index: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  isDraggable: PropTypes.bool,
};
DraggableJob.defaultProps = {
  job: undefined,
  item: undefined,
  index: undefined,
  isDraggable: true,
};

export { DraggableJob };
