import { fragments } from './fragments';

export default {
  types: {
    option: {

      selections: {
        default: `
          id primary secondary name data weight value
        `,
      },

      fields: {
        primary: { type: 'String', label: 'Label', validation: { required: true } },
        secondary: { type: 'String', label: 'Description', validation: { required: false } },
        data: { type: 'Json', validation: { required: false } },
        weight: { type: 'Float', defaultValue: 0, validation: { required: false } },
        name: {
          type: 'String',
          label: 'Data Field',
          validation: { required: true },
          options: [
            { value: 'concrete-mix', primary: 'Concrete Mix' },
            { value: 'yard-product', primary: 'Yard Product' },
            { value: 'payment-by', primary: 'Payment Method' },
            { value: 'mpa', primary: 'MPA' },
            { value: 'aggregate-size', primary: 'Aggegate Size' },
            { value: 'concrete-type', primary: 'Concrete Type' },
            { value: 'concrete-additive', primary: 'Concrete Additive' },
            // { value: 'yard', primary: 'Yard' },
          ],
        },
      },
    },
  },
};
