import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { JobForm } from './JobForm';
import { LocalNodeProvider } from '../../../kiska/components/LocalNodeContext';
import { JobBoardJobView } from 'src/components/dashboards/JobBoard/JobBoardJobView';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 2, 0),
    '& > div': {
      maxWidth: 800,
    },
  },
}));

const JobViewForm = forwardRef((props, ref) => {
  const classes = useStyles(props);
  const { node } = props;

  return (
    <LocalNodeProvider node={node} type="job">
      <div className={classes.root}>
        <JobBoardJobView job={node} />
      </div>
    </LocalNodeProvider>
  );
});

JobViewForm.propTypes = {
  node: PropTypes.object.isRequired,
};
JobViewForm.defaultProps = {
};
JobViewForm.displayName = 'JobViewForm';

export { JobViewForm };
