import React, { useMemo, forwardRef, useContext } from 'react';
import { Typography, InputAdornment, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Grid } from 'src/kiska/components/Grid';
import CustomerIcon from 'mdi-material-ui/AccountTie';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { GridLineBreak } from 'src/components/forms';
import { FormSection } from 'src/components/forms/FormSection';
import { TextField, FileField, NodeField, BooleanField, DateTimeField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import EditButton from 'src/components/updates/EditButton';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';
import ChangeOrderIcon from 'mdi-material-ui/ArrowDecision';
import { AttachFile as FilesIcon } from '@material-ui/icons';
import MedicalIcon from 'mdi-material-ui/Ambulance';
import _ from 'lodash';
import { useNodes } from 'src/kiska/hooks/useNode';
import Fixture from 'src/kiska/components/MutationContext/FormFields/Fixture';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { ReportTitle } from 'src/components/dashboards/ReportTitle';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { CustomerUpdateForm } from '../customer/CustomerUpdateForm';
import { Hideable } from '../../../kiska/components/Hideable';
import { useLocalNode } from '../../../kiska/components/LocalNodeContext';
import { generateShortId } from './utils';
import { ContactRenderer } from '../customer/ContactRenderer';
import { ElectricalJobForm } from './job_types/ElectricalJobForm';
import { useChangeOrderColumns } from '../form/forms/useChangeOrdersColumns';
import { FormViewDialog } from '../form/FormViewDialog';
import { forms } from '../form/forms';
import { useSafetyRecordColumns } from '../form/forms/useSafetyRecordColumns';
import { Calendar } from '../calendar/Calendar';
import { usePrevDailySafetyFormValues } from '../form/forms/usePrevDailySafetyFormValues';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 1, 2, 1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    // margin: theme.spacing(-0, -2, -2, -2),
  },
  dialogContent: {
    padding: 0,
  },
  header: {
    // marginBottom: theme.spacing(-4),
  },
  customerViewRenderer: {
    width: '100%',
  },
  listFormSectionContent: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  calendarFormSectionContent: {
    padding: theme.spacing(0, 1, 2, 1),
  },
  titleUpdateWrapper: {
    padding: theme.spacing(2, 0, 4, 0),
  },
  updateDeliverAtRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const CustomerViewRenderer = ({ node }) => {
  const { printMode } = usePrint();
  const classes = useStyles();
  const { id } = node || {};

  return (
    <div className={classes.customerViewRenderer}>
      <ContactRenderer contact={node} titleProps={{ variant: printMode ? 'body1' : 'h6' }} />
      {!printMode && (
        <Grid container justify="flex-end">
          <CustomerUpdateForm
            id={id}
            trigger={<EditButton icon={CustomerIcon} color="primary" size="small" variant="contained" style={{ marginBottom: 8 }}>Edit Customer</EditButton>}
          />
        </Grid>
      )}
    </div>
  );
};

const JobForm = forwardRef((props, ref) => {
  const { appSettings } = useAppSettings();
  const { variant } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { view, update } = useViewOrUpdate();
  const { node } = useLocalNode();
  const job = node;
  const isNew = !node || !node.id;
  const { printMode } = usePrint();
  const initialShortId = useMemo(generateShortId, [node && node.id]);
  const { columns: changeOrderColumns } = useChangeOrderColumns({});
  const { columns: dailySafetyColumns } = useSafetyRecordColumns({});
  const { nodes: tasks } = useNodes({ type: 'task' });
  const { initialValues: safetyFormInitialValues } = usePrevDailySafetyFormValues({ job });
  const { getOption } = useSchema();
  const mutationContext = useMutationContext();

  if (!tasks.length) return null;

  const basicFieldsFilled = () => {
    return job.title && job.type && job.customer && _.get(job, 'specs.callType');
  };

  let title = '';
  if (job.type) title = getOption('job', 'type', job).primary;
  if (job.customer) title = `${title} - ${job.customer.company || job.customer.name}`;

  const type = update ? mutationContext.getFieldValue('type') : job.type;
  const loadType = ['concrete', 'yard-product'].includes(type);

  const filterTypeOptions = (option) => {
    if (variant === 'days-board') {
      return ['concrete', 'yard-product'].includes(option.value);
    }
    return true;
  };

  const handleCustomerChanged = (name, value) => {
    if (!value) return;

    if (value.address1) {
      const deliveryAddress = [
        value.address1,
        value.address2,
        value.city,
      ].filter((s) => !!s).join('\n');
      mutationContext.onChange('specs.deliveryAddress', deliveryAddress);
    }
    if (value.phone) {
      mutationContext.onChange('specs.deliveryPhone', value.phone);
    }
  };

  if (variant === 'update-deliver-at') {
    return (
      <div className={classes.updateDeliverAtRoot}>
        <DateTimeField static name="deliverAt" label="Delivery Time & Date" pickerProps={{ openTo: 'hours' }} />
      </div>
    );
  }

  return (
    <div className={classes.root} ref={ref} style={{ paddingBottom: update ? '48px' : undefined }}>
      {appSettings.data.jobs.allJobsAllTasks && update && <Fixture name="tasks" value={tasks} />}

      {update && variant === 'days-board' && (
        <>
          <Fixture name="title" value={title} />
          <Fixture name="shortId" value={initialShortId} />
        </>
      )}

      {update && variant !== 'days-board' && (
        <>
          <div className={classes.titleUpdateWrapper}>
            <TextField name="title" label="Job Title" omitFromView />
          </div>
          <Fixture name="shortId" value={initialShortId} />
        </>
      )}

      {view && printMode && (
        <ReportTitle title={job.title} />
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SelectField name="type" label="Job Type" filterOptions={filterTypeOptions} />
        </Grid>

        {/* {variant === 'job-board' && ( */}
        <Grid item xs={12} md={6}>
          <SelectField name="status" label="Status" />
        </Grid>
        {/* )} */}

        <GridLineBreak />

        <Grid item xs={12} md={8}>
          <NodeField
            name="customer"
            label="Customer"
            creatableUpdateForm={CustomerUpdateForm}
            creatableInputFieldMapping="company"
            viewRenderer={CustomerViewRenderer}
            onChange={handleCustomerChanged}
          />
        </Grid>

        {variant === 'days-board' && (
          <>
            <Grid item xs={12} md={4}>
              <SelectField name="specs.payment" label="Payment" initialValue={type === 'concrete' ? '34DACHKEB' : undefined} />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField name="specs.deliveryAddress" label="Delivery Address" rows={3} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField name="specs.deliveryPhone" label="Phone" type="tel" />
            </Grid>

            <Hideable dep="type" showIfEq="concrete">
              <Grid item xs={12} md={4}>
                <SelectField name="specs.pump" label="Pump" />
              </Grid>
            </Hideable>
            <GridLineBreak />

            <Hideable dep="type" showIfEq="concrete">
              <Grid item xs={12} md={12}>
                <TextField name="specs.concreteMix" label="Mix" />
              </Grid>
              <Hideable dep="specs.concreteMix" showIfEq="concrete-mix:other">
                <Grid item xs={12} md={6}>
                  <TextField name="specs.concreteMixOther" label="Other" />
                </Grid>
              </Hideable>
            </Hideable>

            <Hideable dep="type" showIfEq="yard-product">
              <Grid item xs={12} md={12}>
                <TextField name="specs.yardMix" label="Product" />
              </Grid>
              <Hideable dep="specs.yardMix" showIfEq="yard-product:other">
                <Grid item xs={12} md={6}>
                  <TextField name="specs.yardMixOther" label="Other" />
                </Grid>
              </Hideable>
            </Hideable>
            <GridLineBreak />

            <Hideable dep="type" showIfEq="concrete">
              <Grid item xs={6} md={6}>
                <SelectField name="specs.mpa" label="MPA" />
              </Grid>
              <Grid item xs={6} md={6}>
                <SelectField name="specs.aggregateSize" label="Aggs Size" />
              </Grid>

              <Grid item xs={12} md={6}>
                <SelectField name="specs.concreteType" label="Type / Slump" />
              </Grid>
              <Hideable dep="specs.concreteType" showIfEq="concrete-type:other">
                <Grid item xs={12} md={6}>
                  <TextField name="specs.concreteTypeOther" label="Other" />
                </Grid>
              </Hideable>
              <GridLineBreak />

              <Grid item xs={12} md={12}>
                <SelectField name="specs.additives" label="Additives" multiple />
              </Grid>
            </Hideable>

            <Grid item xs={12} md={3}>
              <BooleanField name="specs.goOrder" label="Go Order" switchy={false} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="specs.amount" label="Amount" />
            </Grid>
            <Grid item xs={12} md={3}>
              <SelectField
                name="specs.amountUnits"
                label="Units"
                filterOptions={(o) => {
                  if (type === 'concrete') return o.value === 'm3';
                  if (type === 'yard-product') return ['tonne', 'yrd3'].includes(o.value);
                  return true;
                }}
              />
            </Grid>

            <Grid item xs={undefined} md={undefined}>
              <BooleanField name="specs.weatherPending" label="WP" switchy={false} helperText="Weather Permitting" />
            </Grid>
            <Grid item xs={undefined} md={undefined}>
              <BooleanField name="specs.toBeConfirmed" label="TBC" switchy={false} helperText="To Be Confirmed" />
            </Grid>
            <Hideable dep="type" showIfEq="concrete">
              <Grid item xs={true} md={true}>
                <TextField name="specs.finisher" label="Finisher" />
              </Grid>
            </Hideable>
            <GridLineBreak />

            <Grid item xs={12} md={4}>
              <DateTimeField name="deliverAt" label="Delivery Time & Date" pickerProps={{ openTo: 'hours' }} />
            </Grid>
            <GridLineBreak />
          </>
        )}

        <Grid item xs={12} md={12} lg={12}>
          <TextField name="specs.notes" label="Notes" rows={6} />
        </Grid>
      </Grid>

      {/* Assignments */}
      {!loadType && appSettings.data.jobs.useAssignments && !appSettings.data.jobs.useScheduling && (
        <>
          <br />
          <Hideable hidden={printMode}>
            <FormSection title="Worker Assignments" alwaysInitiallyExpanded={isNew} icon={WorkerIcon}>
              <Grid item xs={12}>
                <NodeField name="assignedUsers" label="Workers Assigned to Job" />
              </Grid>
            </FormSection>
          </Hideable>
        </>
      )}

      <br />
      <FormSection title={`Files (${isNew ? 0 : job.files.length})`} icon={FilesIcon} initiallyExpanded={false}>
        <Grid item xs={12}>
          <FileField name="files" label="" />
        </Grid>
      </FormSection>
    </div>
  );
});

JobForm.displayName = 'JobForm';

export { JobForm };
