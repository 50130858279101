const task = {
  basic: `
    id label 
  `,
};

const file = {
  basic: `
    id url mimeType size name
  `,
};

// const task = {
//   basic: `

//   `,
// };

// const task = {
//   basic: `

//   `,
// };

// const task = {
//   basic: `

//   `,
// };

// const task = {
//   basic: `

//   `,
// };

const user = {
  basic: `
    id displayName email firstName lastName phone
    avatar {id url mimeType name }
    role { id label }
    options preferences
  `,
};

const customer = {
  basic: `
    id name company address1 address2 email phone city state postalCode country notes
  `,
};

const job = {
  basic: `
    id shortId title options specs type createdAt updatedAt status deliverAt
    customer {${customer.basic}}
  `,
};

const event = {
  basic: `
    id start end type allDay data title description
    job { 
      id title shortId type status
      customer {${customer.basic}}
      tasks { id task {
        ${task.basic}
      }}
      lastWorkEntry: workEntries(limit: 1, order_by: [{start: desc}]){
        start end
        user {
          id displayName email
          avatar {id url mimeType name }
        }
      }
    }
    user { ${user.basic} }
  `,
};

const fragments = { customer, job, file, task, user, event };
export { fragments };
