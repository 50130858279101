import deepFreeze from 'deep-freeze';
import produce from 'immer';
import _ from 'lodash';
import { getPermissions } from '../components/contexts/permissions';

const homepageMap = {
  admin: '/app/job-board',
  manager: '/app/job-board',
  'office-admin': '/app/job-board',
  employee: '/app/job-switcher',
};

const buildAnonUser = () => ({
  id: '',
  displayName: 'Anonymous',
  name: 'anon',
  role: { label: 'Anonymous', id: 'anon' },
  payRates: [],
});

const normalizeUser = ({ kiskaUser, auth0User }) => {
  let user;

  if (!kiskaUser || !kiskaUser.id) {
    user = buildAnonUser();
  } else {
    user = kiskaUser;
  }

  user = produce(user, (draft) => {
    draft.auth0User = auth0User;
    // draft.role = { id: 'admin', label: 'Admin' };
    // draft.role = { id: 'manager', label: 'Manger' };
    // draft.role = { id: 'office-admin', label: 'Office Admin' };
    // draft.role = { id: 'employee', label: 'Employee' };
    // draft.role = { id: 'contractor', label: 'Contractor' };
    draft.permissions = getPermissions(draft.role, user);

    _.set(draft, 'preferences.homepage', homepageMap[draft.role.id]);
  });

  deepFreeze(user);
  return user;
};

export {
  normalizeUser,
  buildAnonUser,
};
