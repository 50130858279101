/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNodes } from 'src/kiska/hooks/useNode';
import { addDays, set, isSameDay, parseISO, isToday, eachDayOfInterval } from 'date-fns';
import { formatDate } from 'src/kiska/utils';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { Today as TodayIcon, Add as AddIcon } from '@material-ui/icons';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { JobUpdateForm } from '../modules/job/JobUpdateForm';
import { DraggableJob } from './JobBoard/DraggableJob';

import noWorkBackground from './images/beach3.jpg';
import cementTruck from './JobBoard/images/cement.png';
import gravelTruck from './JobBoard/images/gravel.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column !important',
  },
  days: {
    height: `calc(100vh - 48px)`,
    overflowY: 'auto',
    scrollSnapType: 'y mandatory',
  },
  day: {
    scrollSnapAlign: 'start',
    '&.draggingOver': {
      backgroundColor: `hsla(110, 100%, 50%, 5%)`,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `solid 1px`,
    borderBottomColor: theme.palette.border.light,
    backgroundColor: 'hsla(0, 0%, 0%, 2.5%)',
    padding: theme.spacing(0, 1),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    width: 270,
  },
  center: {

  },
  buttons: {
  },
  button: {
    padding: theme.spacing(0.5),
  },
  jobs: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    position: 'relative',
    flex: 1,
    minHeight: 200,
  },
  job: {
    maxWidth: 'none',
    minWidth: 350,
    width: 350,
    flexBasis: 350,
  },
  value: {
    backgroundColor: `hsla(0, 0%, 0%, 10%)`,
    borderRadius: 4,
    padding: `0px 3px 1px 3px`,
  },
  noWork: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    objectFit: 'cover',
    overflow: 'hidden',
    backgroundImage: `url(${noWorkBackground})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `50% 70%`,
    opacity: 0.1,
  },
  typeButtons: {
    display: 'flex',
    flexDirection: 'row !important',
    height: `calc(100vh - 48px)`,
    alignItems: 'center !important',
    justifyContent: 'center !important',
    flexWrap: 'wrap',
  },
  typeButton: {
    width: '300px !important',
    margin: theme.spacing(2, 2),
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > img': {
        height: 75,
        marginBottom: 8,
      },
    },
  },
}));

const DaysBoard = (props) => {
  const classes = useStyles(props);
  const today = formatDate(new Date(), 'yyyy-MM-dd');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = useMemo(() => addDays(new Date(), -7), [today]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const endDate = useMemo(() => addDays(new Date(), 14), [today]);
  const [activeUpdateNode, setActiveUpdateNode] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [updateInitialValues, setUpdateInitialValues] = useState({});
  const [updateVariant, setUpdateVariant] = useState({});
  const { type } = useParams();
  const log = useLog();
  const { nodes: jobs } = useNodes({
    type: 'job',
    orderBy: [{ deliverAt: 'asc' }],
    limit: 1000,
    where: {
      status: { _in: ['active', 'complete'] },
      type: { _eq: type },
      deliverAt: {
        _gte: startDate,
        _lte: endDate,
      },
    },
  });

  const days = useMemo(() => {
    return eachDayOfInterval({ start: startDate, end: endDate }).map((date) => {
      const daysJobs = jobs.filter((job) => isSameDay(parseISO(job.deliverAt), date));
      const daysCustomers = [...new Map(daysJobs.map((job) => job.customer).map((o) => [o.id, o])).values()];

      return {
        key: date.getTime(),
        isToday: isToday(date),
        dayOfWeek: formatDate(date, `EEEE`),
        monthDate: formatDate(date, `MMM d`),
        jobs: daysJobs,
        customers: daysCustomers,
        startTime: daysJobs.length ? formatDate(daysJobs[0].deliverAt, `h:mm:aaaaa'm`) : '',
        endTime: daysJobs.length ? formatDate(daysJobs[daysJobs.length - 1].deliverAt, `h:mm:aaaaa'm`) : '',
        date,
      };
    });
  }, [endDate, startDate, jobs]);

  const goToToday = () => {
    const todayEl = document.querySelector('.snappable-isToday');
    if (!todayEl) return;
    todayEl.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    goToToday();
  }, [today, type]);

  const handleAddJob = (date) => () => {
    setActiveUpdateNode(null);
    setUpdateDialogOpen(true);
    setUpdateInitialValues({
      deliverAt: set(date, { hours: 7, minutes: 0, seconds: 0 }),
    });
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setActiveUpdateNode(null);
    setUpdateInitialValues({});
    setUpdateVariant(null);
  };

  const handleUpdate = (job, { variant, initialValues } = {}) => () => {
    setUpdateDialogOpen(true);
    setActiveUpdateNode(job);
    setUpdateVariant(variant);
    setUpdateInitialValues(initialValues);
  };

  const handleDragEnd = useCallback((result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) return;
    // No change
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    const sourceDay = days.find((day) => day.key == source.droppableId);
    const destDay = days.find((day) => day.key == destination.droppableId);
    if (!sourceDay) { log.error(`Can't find source day "${source.droppableId}"`); return; }
    if (!destDay) { log.error(`Can't find dest day "${destination.droppableId}"`); return; }

    const job = sourceDay.jobs.find((j) => j.id == draggableId);
    if (!job) { log.error(`Can't find job "${draggableId}"`); return; }

    let deliverAt;
    if (destination.index === 0 || !destDay.jobs.length) {
      deliverAt = set(destDay.date, { hours: 7, minutes: 0, seconds: 0 });
    } else {
      deliverAt = destDay.jobs[destination.index - 1].deliverAt;
    }

    handleUpdate(job, { variant: 'update-deliver-at', initialValues: { deliverAt } })();
  }, [days, log]);

  if (!type) {
    return (
      <div className={classes.typeButtons}>
        <Button className={classes.typeButton} color="default" variant="outlined" component={RouterLink} to="/app/job-board/concrete">
          <img src={cementTruck} />
          <Typography variant="h5" color="textSecondary">Concrete</Typography>
        </Button>
        <Button className={classes.typeButton} color="default" variant="outlined" component={RouterLink} to="/app/job-board/yard-product">
          <img src={gravelTruck} />
          <Typography variant="h5" color="textSecondary">Yard Products</Typography>
        </Button>
      </div>
    );
  }

  return (
    <>
      <JobUpdateForm
        id={activeUpdateNode && activeUpdateNode.id}
        open={updateDialogOpen}
        onClose={handleUpdateDialogClose}
        onNodeChange={(node) => setActiveUpdateNode(node)}
        forcedInitialValues={updateInitialValues}
        initialValues={{
          type,
          'specs.amountUnits': type === 'concrete' ? 'm3' : 'yrd3',
          'specs.payment': type === 'concrete' ? '34DACHKEB' : undefined,
        }}
        variant="days-board"
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={classes.root}>
          <div className={classes.days}>
            {days.map((day) => {
              return (
                <Droppable key={`${day.key}`} droppableId={`${day.key}`} direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={classNames(classes.day, snapshot.isDraggingOver && 'draggingOver', day.isToday && 'snappable-isToday')}
                      {...provided.droppableProps}
                    >
                      <div className={classes.heading}>
                        <div className={classes.title}>
                          <Typography variant="h6" color={day.isToday ? 'primary' : 'textPrimary'}>{day.isToday ? 'TODAY - ' : ''}{day.dayOfWeek}</Typography>
                          <Typography variant="body1" color="textSecondary" style={{ paddingLeft: 8 }}>{day.monthDate}</Typography>
                        </div>
                        <div className={classes.center}>
                          <Typography variant="body1" color="textSecondary">
                            <span className={classes.value}>{day.jobs.length}</span>  Jobs
                            {!!day.jobs.length && (
                              <>
                                for <span className={classes.value}>{day.customers.length}</span> Customers
                                from <span className={classes.value}>{day.startTime}</span> - <span className={classes.value}>{day.endTime}</span>
                              </>
                            )}
                          </Typography>
                        </div>
                        <div className={classes.buttons}>
                          <Tooltip title="Add Job">
                            <IconButton color="primary" onClick={handleAddJob(day.date)} className={classes.button}><AddIcon /></IconButton>
                          </Tooltip>
                          {/* <IconButton onClick={goToToday} color="primary"><TodayIcon /></IconButton> */}
                        </div>
                      </div>
                      <div className={classes.jobs}>
                        {day.jobs.map((job, index) => <DraggableJob key={job.id} job={job} index={index} className={classes.job} onUpdate={handleUpdate(job)} />)}
                        {!day.jobs.length && <div className={classes.noWork} />}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </div>
      </DragDropContext>
    </>

  );
};

DaysBoard.propTypes = {
};
DaysBoard.defaultProps = {
};

export { DaysBoard };
